import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const NavbarRight = styled.ul`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media only screen and (max-width: 411px) and (max-height: 812px) and (orientation: landscape) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  li {
    margin-left: 38px;
    a {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      transition: all 0.3s ease;
      &:hover {
        color: ${themeGet('colors.menu', '#edcd37')};
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  alignitems: center;
  justify-content: space-between;
  margin-left: 50px;
  @media only screen and (max-width: 1250px) {
    justify-content: flex-end;
  }
`;
