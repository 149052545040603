import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Button from 'common/src/components/Button';
import Logo from 'common/src/components/UIElements/Logo';
import Box from 'common/src/components/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Link from 'common/src/components/Link';

import LogoImage from 'common/src/assets/image/saasModern/logo-white.svg';
import LogoImageAlt from 'common/src/assets/image/saasModern/logo.png';
import { NavbarRight, MenuWrapper } from './navbar.style';
import useToken from 'common/src/hooks/useToken';
import { addTokenToUrl } from 'common/src/utils/token';

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE'
    });
  };

  const authToken = useToken();
  const addAuthTokenToUrl = url => addTokenToUrl({ authToken: authToken, url });

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <MenuWrapper>
            <Box {...menuWrapper}>
              <ScrollSpyMenu
                className="main_menu"
                menuItems={Data.saasModernJson.MENU_ITEMS}
                offset={-70}
              />
              <Drawer
                width="420px"
                placement="right"
                drawerHandler={<HamburgMenu barColor="#fff" />}
                open={state.isOpen}
                toggleHandler={toggleHandler}
              >
                <ScrollSpyMenu
                  className="mobile_menu"
                  menuItems={Data.saasModernJson.MENU_ITEMS}
                  drawerClose={true}
                  offset={-100}
                />

                <a
                  rel="noopener"
                  href={addAuthTokenToUrl(
                    'https://app.magictableplanner.com/login'
                  )}
                  className="navbar_drawer_button"
                >
                  <Button {...button} title="Log in" />
                </a>
                <a
                  rel="noopener"
                  href={addAuthTokenToUrl(
                    'https://app.magictableplanner.com/signup'
                  )}
                  className="navbar_drawer_button"
                >
                  <Button {...button} title="Register" />
                </a>
              </Drawer>
            </Box>
            <Box {...menuWrapper}>
              <NavbarRight className="navbar_button">
                <li>
                  <Link
                    rel="noopener"
                    href={addAuthTokenToUrl(
                      'https://app.magictableplanner.com/login'
                    )}
                    target="_blank"
                    className="login"
                  >
                    Login
                  </Link>
                </li>
                <li>
                  <a
                    href={addAuthTokenToUrl(
                      'https://app.magictableplanner.com/signup'
                    )}
                    target="_blank"
                    rel="noopener"
                  >
                    <Button {...button} title="GET STARTED" />
                  </a>
                </li>
              </NavbarRight>
            </Box>
          </MenuWrapper>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block'
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  logoStyle: {
    maxWidth: ['150px', '160px']
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px'
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center'
  }
};

export default Navbar;
